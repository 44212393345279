.payment-formation-general {
  width: 100%;
  height: 100%;
  color: #0c0c0f;
  text-align: left;
  box-sizing: border-box;

  .payment-balance {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    margin-top: 18px;
    padding: 21px 41px;
    font-size: 14px;
    margin-bottom: 25px;

    .payment-label {
      svg {
        margin-right: 8px;
      }
    }
    .payment-end {
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 2px solid rgba(196, 203, 214, 0.3);
    }
    .reneral-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .reneral-item-lable {
        width: 100px;
        color: #4e5969;
        font-size: 14px;
      }
      .reneral-item-value {
        width: 100px;
        text-align: right;
        color: #0a1629;
        font-size: 14px;
        font-weight: 600;
        margin-right: 20px;
      }
    }
    .payment-prcieDetail {
      // width: 100%;
      padding: 20px 0 5px;
      font-size: 24px;
      line-height: 19px;
      color: #1d2129;
      // display: flex;
      span {
        color: #406eff;
        font-size: 24px;
        font-weight: 600;
      }
      .total-money-symbol {
        color: #406eff;
        font-size: 18px;
      }
      .money-symbol {
        font-size: 18px;
        font-weight: 500;
        color: #1d2129;
      }
    }
    .payment-prcieDetail-text {
      width: fit-content;
      margin-top: 20px;
      border-radius: 10px;
      line-height: 36px;
      padding: 0 11px;
      color: #7598c4;
      font-size: 16px;
      background: linear-gradient(273deg, #deecff 0%, #f2f8ff 99%);
    }
    .arco-statistic-content .arco-statistic-value {
      color: #406eff;
    }
    .arco-statistic-content .arco-statistic-value-int {
      font-size: 44px;
    }
    .monthly-settlement {
      margin-right: 19px;
      span {
        margin-left: 4px;
        margin-right: 6px;
      }
      .arco-icon {
        font-size: 18px;
        color: #406eff;
        margin-left: 19px;
        margin-top: 4px;
        cursor: pointer;
        vertical-align: sub;
      }
    }
    .btn-Recharge {
      width: 117px;
      height: 42px;
      background: #406eff;
      border-radius: 8px;
      margin-left: 31px;
    }

    .Link-btn {
      margin-left: 29px;
      .arco-link {
        color: #1d2129;
        font-size: 16px;
      }
      .arco-link-disabled {
        color: #ccc;
      }
      .arco-space-item {
        margin-right: 0px !important;
      }
    }

    .arco-statistic-value-prefix,
    .arco-statistic-value-suffix {
      font-size: 28px;
    }
  }
  .payment-prcieDetail {
    .btn-Recharge {
      width: 117px;
      height: 42px;
      background: #406eff;
      border-radius: 14px;
      margin-left: 5px;
      color: #fff;
      border: 1px solid transparent;
      span {
        color: #fff;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}
.general-modal {
  .arco-modal-content {
    padding: 0 20px 14px !important;
  }
  .arco-modal-title {
    font-size: 18px;
    color: #0a1629;
  }
  .arco-modal-footer {
    button {
      font-size: 16px;
      width: 98px;
      height: 48px;
      border-radius: 14px;
    }
  }
  .payment-explain {
    padding: 0 0 16px;
    background-color: #fff;
  }
  .general-modal-item {
    font-size: 15px;
    color: #20242f;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .general-text {
      width: 110px;
      margin-right: 15px;
    }
    .general-modal-input {
      border-radius: 14px;
      height: 43px;
      border: 1px solid #d8e0f0;
      background-color: #fff;
    }
    .arco-input-inner-wrapper {
      border-radius: 14px;
    }
    .arco-input-number-step-button {
      background-color: transparent;
      .arco-icon {
        margin-right: 0;
      }
    }
  }
}
