.payment {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 30px;
  padding-right: 20px;
  color: #0c0c0f;
  text-align: left;
  box-sizing: border-box;

  .GroupTable-fixe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .arco-btn-icon-only {
      width: 45px;
      height: 45px;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    }

    .Header-init-btn {
      width: 118px;
      height: 45px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.26);
    }

    .GroupTable {
      width: auto;
      height: 45px;
      background: #f2f6ff;
      border-radius: 24px;
      overflow: hidden;

      .arco-radio-button {
        width: 111px;
        height: 38px;
        line-height: 38px;
        color: #1d2129;
        font-size: 14px;
        border-radius: 20px;
        text-align: center;
      }

      .arco-radio-button:hover {
        border-radius: 24px;
      }

      .arco-radio-button::after {
        background: #f2f6ff;
      }

      .arco-radio-button:not(:first-of-type)::before {
        background: transparent;
        color: transparent;
      }

      .arco-radio-button.arco-radio-checked {
        width: 111px;
        height: 38px;
        background: #406eff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
      }

      .arco-radio-button-inner {
        border-radius: 20px;
      }
    }
  }

  .Table-card.arco-card-size-default .arco-card-body {
    margin-bottom: 16px;
    padding-top: 0;
  }

  .outline {
    width: auto;
    height: 32px;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
  }
}
