.billing-method{
    width: 100%;
    height: 100%;
    padding: 0;
    padding-left: 28px;
    padding-right: 20px;
    box-sizing: border-box;
    .billing-list{
        width: 100%;
        min-width: 1300px;
        height:auto;
        border-radius: 22px;
        padding: 32px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
        margin-top: 18px;
        margin-bottom: 10px;
        .billingBanner{
            width: 100%;
            height: 153px;
            border-radius: 20px;
            background: linear-gradient(93deg, #E7F4FF 16%, #E7F4FF 19%, #DFF4FF 85%, #DFF4FF 85%);
            background-image: url('/asserts/billingBanner.svg');
            background-position: center;
            background-repeat: no-repeat;
            color: #94B3C5;
            margin-bottom: 31px;
            div:first-of-type{
                padding-top: 48px;
                font-size: 28px;
                line-height: 22px;
                color: #4C71B5;
                box-sizing: border-box;
            }
            div:last-of-type{
                margin-top: 17px;
                font-size: 14px;
                line-height: 24px;
                color: #94B3C5;
            }
        }
        .table-explains{
            position: absolute;
            right: 0;
            height: 100%;
        }
        .billing-table{
            // .table-td-full{
            //     z-index: 2;
            //     border-right: 1px solid #E6EBF5;
            // }
            .table-td{
                // position: relative;
            }
            .table-th{
                .table-product{
                    position: absolute;
                    top: 0;
                    left: 0;
                    box-sizing: border-box;
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // padding-left: 35%;
                    min-height: 57px;
                    border-right: 1px solid #E6EBF5;
                    border-top: none;
                    &::after{
                        content: '';
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-color: #E6EBF5;
                    }
                }
                .table-product:last-child{
                    &::after{
                        content: '';
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .payment-explain{
        margin-bottom: 21px;
        margin-top: 18px;

    }
}