.payment-formation {
  width: 100%;
  height: 100%;
  color: #0c0c0f;
  text-align: left;
  box-sizing: border-box;

  .payment-balance {
    width: 100%;
    height: 296px;

    background: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    margin-top: 18px;
    padding: 21px 50px;
    font-size: 14px;
    margin-bottom: 25px;
    .payment-balance .columnSpaceStart1 {
      display: flex;
      flex-direction: column;
      // justify-content: start;
      align-items: flex-start;
    }
    .payment-label {
      .icon {
        width: 16px;
        height: 16px;
        background-image: url('/asserts/icon-AvailableBalance.svg');
        margin-right: 6px;
      }
      .arco-link {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #406eff;
      }
    }
    .payment-end-border {
      width: 100%;
      height: 14px;
      background-image: url('/asserts/payment-border.svg');
      // border-bottom: 2px dashed #d8d8d8;
    }
    .payment-PriceDetail {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 20px;
      .payment-PriceDetail-top {
        width: 430px;
        display: flex;
        justify-content: space-between;
        .payment-PriceDetail-top-txt {
          font-size: 14px;
          line-height: 22px;
          text-align: right;
          margin-top: 14.5px;
          color: #4e5969;
        }
        .payment-PriceDetail-top-txt:nth-child(1) {
          margin-left: 26px;
        }
        .payment-PriceDetail-top-txt:nth-child(3) {
          margin-right: 16px;
        }
      }
      .payment-PriceDetail-bottom {
        width: 430px;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        .payment-PriceDetail-bottom-num {
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          letter-spacing: 0px;
          color: #406eff;
          margin-bottom: 20px;
        }
      }
    }

    .payment-prcieDetail {
      padding: 16px 0 5px;
      font-size: 24px;
      line-height: 19px;
      color: #1d2129;
      display: flex;
      width: 100%;
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .amount-num {
        color: #6a8cce;
        font-size: 24px;
      }
      .total-money-symbol {
        color: #6a8cce;
        font-size: 24px;
        margin-right: 8px;
      }
      .amount-txt {
        font-size: 16px;
        text-align: center;
        line-height: 32px;
        margin-top: 16px;
        color: #a4a9b0;
      }
      // .money-symbol{
      //     font-size: 18px;
      //     font-weight: 500;
      //     color: #1D2129;
      // }
    }
    .payment-prcieDetail-text {
      width: fit-content;
      margin-top: 20px;
      border-radius: 10px;
      line-height: 36px;
      padding: 0 11px;
      color: #7598c4;
      font-size: 16px;
      background: linear-gradient(273deg, #deecff 0%, #f2f8ff 99%);
    }
    .arco-statistic-content .arco-statistic-value {
      color: #406eff;
      margin-top: 18px;
      // margin-bottom: 14.5px;
    }
    .arco-statistic-content .arco-statistic-value-int {
      line-height: 22px;
      font-size: 30px;
      font-weight: 700;
    }
    .arco-statistic-content .arco-statistic-value-decimal {
      line-height: 22px;
      font-size: 30px;
      font-weight: 700;
    }
    .monthly-settlement {
      margin-right: 19px;
      span {
        margin-left: 4px;
        margin-right: 6px;
      }
      .arco-icon {
        font-size: 18px;
        color: #406eff;
        margin-left: 19px;
        margin-top: 4px;
        cursor: pointer;
        vertical-align: sub;
      }
    }
    .btn-Recharge {
      width: 132px;
      height: 48px;
      background: #406eff;
      border-radius: 14px;
      box-shadow: 0px 6px 12px 0px rgba(63, 140, 255, 0.2637);
    }

    .Link-btn {
      margin-left: 29px;
      .arco-link {
        color: #1d2129;
        font-size: 16px;
      }
      .arco-link-disabled {
        color: #ccc;
      }
      .arco-space-item {
        margin-right: 0px !important;
      }
    }

    .arco-statistic-value-prefix,
    .arco-statistic-value-suffix {
      font-size: 28px;
    }
  }
  .payment-overview {
    width: 100%;
    height: 234px;
    background: #fff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    margin-top: 20px;
    padding: 24px 50px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .payment-overview-title {
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      font-weight: 500;
      .icon {
        width: 16px;
        height: 16px;
        background-image: url('/asserts/icon-ConsumptionInformation.svg');
        background-image: url('/asserts/icon-AvailableBalance.svg');
        margin-right: 6px;
      }
    }
    .payment-card-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }
    .payment-card1 {
      margin: 0 16px 0 0;
      width: 206px;
      height: 94px;
      background: linear-gradient(
        180deg,
        #c9f6ca 5%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px solid #63bc78;
      border-radius: 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .payment-card-left {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 12px;
        background: #ffffff;
        z-index: 1;
        .icon {
          position: absolute;
          left: 9px;
          top: 7px;
          width: 32px;
          height: 36px;
          background-image: url('/asserts/calendar-left.svg');
          z-index: 2;
        }
      }
      .payment-card-right {
        span {
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          color: #4e5969;
        }
        .payment-card-right-txt {
          font-size: 14px;
          font-weight: bold;
          line-height: 22px;
          color: #1d2129;
          margin-bottom: 6px;
        }
      }
    }
    .payment-card2 {
      margin: 0 16px 0 0;
      width: 206px;
      height: 94px;
      border-radius: 16px;
      border: 1px solid #72bcea;
      background: linear-gradient(180deg, #d3edfd 0%, #ffffff 100%);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .payment-card-left {
        position: relative;
        width: 50px;
        height: 50px;
        border-radius: 12px;
        background: #ffffff;
        z-index: 1;
        .icon {
          position: absolute;
          left: 9px;
          top: 7px;
          width: 32px;
          height: 36px;
          background-image: url('/asserts/calendar-right.svg');
          z-index: 2;
        }
      }
      .payment-card-right {
        span {
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          color: #4e5969;
        }
        .payment-card-right-txt {
          font-size: 14px;
          font-weight: bold;
          line-height: 22px;
          color: #1d2129;
          margin-bottom: 6px;
        }
      }
    }
  }

  .custom-White-button {
    width: 118px;
    height: 45px;
    border-radius: 14px;
  }
  .arco-icon {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  .loadingList {
    padding: 30px 10px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 22px;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
    box-sizing: border-box;
    .btn-Recharge {
      width: 117px;
      height: 42px;
      background: #406eff;
      border-radius: 8px;
      margin-left: 31px;
      margin: 0 auto;
    }
  }
  .arco-list-wrapper {
    width: 100%;
    min-height: 179px;
  }
  .arco-list-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .arco-list-item {
      // width: 147px;
      width: 287px;
      height: 165px;
      margin: 0 15px;
      margin-bottom: 10px;
      padding: 0 !important;
    }
    .arco-list-header,
    .arco-list-item:not(:last-child) {
      border: 0;
    }
    .arco-list-scroll-loading {
      width: 100%;
      margin: 0;
      height: 0;
    }
  }
  .Emptydata {
    .arco-typography {
      color: #4e5969;
      font-size: 14px;
      margin-top: 17px;
    }
  }
  .engine-resources {
    width: 100%;
    height: 165px;
    .resources {
      position: relative;
      width: 100%;
      min-height: 144px;
      padding-top: 18px;
      padding-bottom: 0;
      border-radius: 22px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #00d097;
      color: #1d2129;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
      cursor: pointer;
      &:hover {
        background-image: var(--gradientcolor);
        .upgrade {
          opacity: 1;
          height: 41px;
          transition: height 0.3s linear, opacity 0.3s 0.1s linear;
        }
      }
      .arco-statistic-content .arco-statistic-value,
      .arco-statistic-content .arco-statistic-value-int,
      .arco-statistic-value-prefix,
      .arco-statistic-value-decimal {
        font-size: 22px;
      }
      .arco-statistic-content .arco-statistic-value-suffix {
        font-size: 12px;
      }
      .resources-box {
        padding: 0 16px;
        box-sizing: border-box;
      }
      .resources-title {
        font-size: 18px;
        margin-bottom: 14px;
      }
      .arco-statistic-extra {
        margin-top: 0;
        color: #4e5969;
        font-size: 12px;
      }
      .resources-type {
        font-size: 12px;
        color: #5887d0;
        .resources-icon {
          height: 50px;
          img {
            height: 100%;
          }
        }
      }
      .resources-group {
        color: var(--color);
      }
      .upgrade {
        opacity: 0;
        width: 100%;
        text-align: center;
        color: #4e5969;
        font-size: 12px;
        height: 0px;
        line-height: 41px;
        transition: opacity 0.1s linear, height 0.4s linear;
        .arco-icon {
          margin-left: 12px;
        }
        span {
          padding: 2px;
          border-radius: 2px;
        }
        span:hover {
          color: var(--color);
        }
        span:active {
          background: rgba(196, 203, 214, 0.1);
        }
      }
    }
  }
  .arco-list-item:has(.chappt-resources) {
    // height: 200px;
  }
  .engine-resources.chappt-resources {
    // height: 200px;
    .bluemodel {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: #406eff;
      height: 35px;
      line-height: 35px;
      border: 1px solid #406eff;
      border-radius: 20px 20px 0 0;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
    .resources-box {
      display: flex;
      height: 100px;
      padding-top: 30px;
      justify-content: space-between;
    }
    .upgrade {
      background: rgba(240, 244, 255, 0.25);
    }
  }
  .payment-explain {
    width: 100%;
    height: auto;
    border-radius: 14px;
    font-size: 12px;
    background: #eff6ff;
    color: #5785bc;
    padding: 16px;
    line-height: 24px;
    box-sizing: border-box;
    margin-bottom: 26px;
    margin-top: 5px;
    .payment-explain-lable {
      color: #414551;
      margin-bottom: 6px;
      .arco-icon {
        color: #ffb83e;
        margin-right: 8px;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }
  }
}
.custom-modal.renew-modal {
  width: 544px;
  .custom-box {
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0px;
    color: #0a1629;
    h4 {
      font-size: 20px;
      margin-bottom: 39px;
    }
    span {
      font-size: 16px;
    }
  }
  .arco-modal-footer > .arco-btn {
    width: 98px;
    height: 48px;
    border-radius: 14px;
  }
}
.payment-modal.arco-modal {
  width: 680px;
  .payment-explain {
    font-size: 16px;
    .payment-modal-detail ul {
      padding-left: 22px;
      li {
        padding: 5px 0;
      }
    }
  }
}

.commonList {
  .arco-list-wrapper,
  .arco-list {
    overflow: inherit;
  }
  .arco-list-content {
    display: flex;
    gap: 20px;
    border-radius: 22px;
    margin: 0 20px;
    padding: 0px 30px 10px;
    overflow: inherit;
    justify-content: flex-start;
    .arco-list-item {
      width: auto;
      height: auto;
      min-width: 32rem;
      overflow: inherit;
    }
    .arco-list-item-main {
      overflow: inherit;
    }
    .services-package-box {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 280px;
      position: relative;
      background-color: #ffffff;
      border-radius: 22px;
      overflow: hidden;
      transition: transform 0.4s linear, -webkit-transform 0.4s linear;
      &:hover {
        transform: translateY(-15px);
      }
    }
    .TEXT-box {
      border: 1px solid #76bce9;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      .services-package-btn {
        background-color: #76bce9;
        &:hover {
          background-color: #60a5d1;
        }
      }
      .tag {
        background-color: #76bce9;
      }
      &:hover {
        background: linear-gradient(180deg, #d3edfd 0%, #ffffff 100%);
      }
    }
    .FILE-box {
      border: 1px solid #b299e4;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      .services-package-btn {
        background-color: #b299e4;
        &:hover {
          background-color: #9f85d1;
        }
      }
      .tag {
        background-color: #b299e4;
      }
      &:hover {
        background: linear-gradient(180deg, #f1e9ff 0%, #ffffff 100%);
      }
    }
    .IMAGE-box {
      border: 1px solid #f2a56c;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      .services-package-btn {
        background-color: #f2a56c;
        &:hover {
          background-color: #de935d;
        }
      }
      .tag {
        background-color: #f2a56c;
      }
      &:hover {
        background: linear-gradient(180deg, #ffe7d6 7%, #ffffff 100%);
      }
    }
    .tag-mode-container {
      font-size: 12px;
      height: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      z-index: 99;
      .tag {
        color: #fff;
        font-size: 12px;
        height: 82px;
        overflow: hidden;
        -webkit-transform: translate(-80px, -32px) rotate(45deg);
        transform: translate(-80px, -32px) rotate(45deg);
        width: 140px;
        span {
          left: 55px;
          position: absolute;
          top: 47px;
          transform: rotate(-40deg);
        }
      }
    }
    .services-package-item {
      display: flex;
      align-items: center;
      text-align: left;
      margin-left: 30px;
      margin-top: 35px;
      img {
        width: 73px;
        height: 73px;
      }
      .services-package-ul {
        margin-left: 30px;
        .services-package-name {
          color: #3d3d3d;
          font-size: 22px;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 10px;
        }
        .services-package-desicrt {
          color: #767676;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
        }
      }
    }
    .services-package-btn {
      margin: 25px 23px 23px 23px;
      height: 48px;
      line-height: 48px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 14px;
      box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      &:hover {
        cursor: pointer;
        color: #ffffff !important;
      }
    }
  }
  .commonList-empty {
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
  }
}
.arco-modal-content {
  margin-bottom: 80px !important;
}
.general-modal-item {
  margin: 20px 0;
}
.payment-app {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
  .payment-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 450px;

    .payment-content-top {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-top: 14.5px;
      color: #4e5969;
    }
    .payment-content-bottom {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: 0px;
      color: #406eff;
      margin-top: 6px;
      margin-bottom: 20px;
    }
  }
}
