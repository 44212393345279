.payment-details {
  .over-header {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .over-header-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arco-dropdown-popup-visible .arco-icon-down {
        transform: rotate(180deg);
      }
      .custom-select {
        margin-left: 10px;
        border: 1px solid #cdd3df;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
      }
      .custom-select:hover {
        border: 1px solid #406eff;
      }
      #infoselect:hover .custom-type {
        color: #406eff;
      }
      .custom-type {
        color: #4e5969;
      }
      .custom-shile {
        margin: 0 10px;
        width: 2px;
        height: 15px;
        background-color: #cdd3df;
      }
      .arco-dropdown-menu-item {
        padding-left: 10px;
      }
    }
    .consumption-select {
      position: relative;
      overflow: hidden;
      margin-left: 16px;
      background-color: #fff;
      .arco-select-addbefore {
        font-size: 14px;
      }
      .arco-select-view {
        width: 140px;
        height: 42px;
        border-color: rgba(255, 255, 255, 0);
        display: flex;
        align-items: center;
        justify-self: center;
        background-color: #fff;
      }
      .arco-select-wrapper {
        overflow: hidden;
        border-radius: 14px;
        background-color: #ffffff;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        border: 1px solid #cdd3df;
        height: 43px;
        &:hover {
          border: 1px solid #406eff;
        }
      }
    }

    .DatePicker-RangePicker {
      position: relative;
      .RangePicker-box {
        border-radius: 14px;
        background-color: #ffffff;
        box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
        border: 1px solid #cdd3df;
        height: 43px;
        &:hover {
          border: 1px solid #406eff;
        }
      }
    }
    .custom-White-button {
      height: 48px;
      border-radius: 12px;
      margin-left: 15px;
    }
  }
}

.arco-picker-size-default input {
  font-size: 12px !important;
}

.arco-radio-group .arco-radio.time-radio {
  border-radius: 12px;
  border: 1px solid #d8e0f0;
  padding: 8px 15px;
  padding-left: 15px !important;
  margin-bottom: 0;
  &:hover {
    border: 1px solid #406eff;
  }
  .arco-radio-text {
    margin-left: 10px !important;
  }
}

.arco-modal-wrapper.arco-modal-wrapper-align-center .arco-modal {
  width: 829px;
  height: 410px;
  border-radius: 24px;
  padding: 0 20px;
  .arco-modal-title {
    margin-top: 21.25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
  }
  .arco-modal-content {
    margin-bottom: 130px;
    .arco-picker {
      width: 237px;
      height: 48px;
      border-radius: 10px;
    }
    .arco-radio-group {
      display: flex;
    }
    .arco-radio {
      display: flex;
      align-items: center;
      width: 124px;
      height: 48px;
      border-radius: 10px;
    }
    .custom-radio-card {
      padding: 10px 16px;
      border: 1px solid var(--color-border-2);
      border-radius: 4px;
      width: 250px;
      box-sizing: border-box;
    }

    .custom-radio-card-mask {
      height: 14px;
      width: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      border: 1px solid var(--color-border-2);
      box-sizing: border-box;
    }
  }
  .arco-modal-footer {
    .arco-btn {
      width: 98px;
      height: 48px;
      border-radius: 14px;
      font-size: 16px;
    }
  }
}

.down-modal {
  width: 829px;
  height: 410px;
  border-radius: 24px;
  .arco-modal-footer button {
    border-radius: 12px;
  }
  .RangePicker-box {
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1036);
    border: 1px solid #cdd3df;
    height: 43px;
    &:hover {
      background-color: #fff;
      border: 1px solid #406eff;
    }
  }
}
.arco-table-tr {
  height: 60px;
}
.arco-table-cell-wrap-value {
  font-style: 14px;
  line-height: 22px;
}

.arco-select-wrapper {
  display: flex;
  align-items: center !important;
  justify-content: center;
  .arco-select-addbefore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 26px;
    line-height: 20px;
    background-color: #fff !important;
    color: #4e5969;
  }
  .arco-select-view {
    font-size: 14px;
    line-height: 20px;
    color: #0b172a;
  }
}
.arco-picker-range-container {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 14px !important;
  background: #ffffff !important;
}
